import styled from 'styled-components'
import {ButtonLink, media, ButtonLight} from '@modifi/ui'
import {airBlue, seaNavy} from '@modifi/colors'
import {CMSBackgroundTiles1, CMSBackgroundTiles2} from '@modifi/website-client'

export const Wrapper = styled.div``

export const StyledButtonLink = styled(ButtonLink)`
  padding-left: 0;
  padding-right: 0;
`

// @todo this modification to be removed and updated with new button changes once live
export const StyledButtonLight = styled(ButtonLight)`
  border: 2px solid ${airBlue};
  margin: 8px 0 0 0;
`

export const WebsiteCmsPageWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`

export const GradientLayer = styled.div`
  position: absolute;
  width: 100%;
  top: 100vh;
  height: calc(100% - 100vh);
  background: linear-gradient(
    179.43deg,
    rgba(83, 162, 217, 0) 0.5%,
    ${airBlue} 19.25%,
    ${seaNavy} 78.63%,
    rgba(21, 83, 159, 0) 100.51%
  );
  opacity: 0.08;
  z-index: -30;
`
export const BackgroundTile2 = styled.div`
  display: none;
  ${media.lg`
    display: block;
  `}
  position: absolute;
  top: 100vh;
  right: 0;
  background-image: url(${CMSBackgroundTiles2});
  width: 250px;
  pointer-events: none;
`

export const BackgroundTile1 = styled.div`
  display: none;
  ${media.lg`
  display: block;
`}
  position: absolute;
  top: 100vh;
  background-image: url(${CMSBackgroundTiles1});
  width: 250px;
  pointer-events: none;
`
