import React from 'react'
import * as PropTypes from 'prop-types'
import {InferPropTypes} from '@modifi/typescript-utils'

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

const defaultProps = {
  children: null,
}

export type VerticalScrollWrapperProps = InferPropTypes<typeof propTypes, typeof defaultProps>

export const VerticalScrollWrapper = React.forwardRef<HTMLSpanElement, VerticalScrollWrapperProps>(
  ({children, ...restProps}, ref) => (
    <span ref={ref} {...restProps} style={{scrollMarginTop: '32px'}}>
      {children}
    </span>
  )
)

VerticalScrollWrapper.propTypes = propTypes

VerticalScrollWrapper.defaultProps = defaultProps

export default VerticalScrollWrapper
