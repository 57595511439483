import {connect} from '@modifi/redux'
import {aPush} from '@modifi/router'
import {
  aFetchWebsiteCmsPage,
  selectWebsiteCmsPage,
  selectWebsiteCmsPageHasError,
  selectWebsiteCmsPageIsLoading,
} from './store'

import WebsiteCmsPage, {RemotePageProps} from './WebsiteCmsPage'

const mapStateToProps = (
  state: Record<string, any>
): Pick<RemotePageProps, 'pageContent' | 'isLoading' | 'hasError'> => ({
  pageContent: selectWebsiteCmsPage(state) ?? undefined,
  isLoading: selectWebsiteCmsPageIsLoading(state),
  hasError: selectWebsiteCmsPageHasError(state),
})

const mapDispatchToProps = {
  fetch: aFetchWebsiteCmsPage,
  redirect: aPush,
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteCmsPage)
