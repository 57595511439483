import React, {useEffect, useRef} from 'react'
import * as PropTypes from 'prop-types'
import {useSsrEffect} from '@modifi/client-ssr'
import {FullScreenLoader} from '@modifi/ui'
import {Helmet} from 'react-helmet'
import {InferPropTypes} from '@modifi/typescript-utils'
import {
  componentsMap,
  getLocaleForSocratesLocale,
  detectPassiveEventsSupport,
} from '@modifi/website-client'

import URLS from '../../routes/constants'
import {
  Wrapper,
  WebsiteCmsPageWrapper,
  GradientLayer,
  BackgroundTile1,
  BackgroundTile2,
} from './styles'
import VerticalScrollWrapper from './components/vertical-scroll-wrapper'

const propTypes = {
  pageType: PropTypes.string.isRequired,
  pageSlug: PropTypes.string.isRequired,
  pageContent: PropTypes.shape({
    locale: PropTypes.string,
    alternativeLocales: PropTypes.arrayOf(PropTypes.string.isRequired),
    fields: PropTypes.shape({
      components: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          // eslint-disable-next-line react/forbid-prop-types
          fields: PropTypes.any,
        })
      ),
      seo: PropTypes.shape({
        seoTitle: PropTypes.string,
        metaDescription: PropTypes.string,
      }),
      hideBackgroundTiles: PropTypes.bool,
      hideBackgroundGradient: PropTypes.bool,
    }),
    updated: PropTypes.string,
    published: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  fetch: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
}
const defaultProps = {
  pageContent: undefined,
}

export type RemotePageProps = InferPropTypes<typeof propTypes, typeof defaultProps>

const CmsPage: React.FC<RemotePageProps> = ({
  pageType,
  pageSlug,
  pageContent,
  fetch,
  isLoading,
  redirect,
  hasError,
  locale,
}) => {
  const mappedLocale = pageContent?.locale ? getLocaleForSocratesLocale(pageContent?.locale) : ''
  const backgroundTile1Ref = useRef<HTMLDivElement>(null)
  const backgroundTile2Ref = useRef<HTMLDivElement>(null)

  const scrollRefs = useRef<HTMLSpanElement[]>([])

  const scrollToComponent = (index: number) => {
    scrollRefs.current[index].scrollIntoView({behavior: 'smooth'})
  }

  const components = pageContent?.fields?.components

  const hideBackgroundTiles = pageContent?.fields?.hideBackgroundTiles
  useEffect(() => {
    if (!hideBackgroundTiles) {
      const handleScroll = () => {
        const pageHeight = backgroundTile1Ref.current?.parentElement?.clientHeight
        const viewPortHeight = window.innerHeight
        const scrolled = window.scrollY
        const rate = scrolled * 0.3
        const backgroundTileHeight = pageHeight! - viewPortHeight - rate

        if (backgroundTile1Ref.current) {
          backgroundTile1Ref.current.style.height = `${backgroundTileHeight}px`
          backgroundTile1Ref.current.style.transform = `translate3d(0px, ${rate}px, 0px)`
        }
        if (backgroundTile2Ref.current) {
          backgroundTile2Ref.current.style.height = `${backgroundTileHeight}px`
          backgroundTile2Ref.current.style.transform = `translate3d(0px, ${rate}px, 0px)`
        }
      }
      if (detectPassiveEventsSupport()) {
        window.addEventListener('scroll', handleScroll, {passive: true})
      } else {
        window.addEventListener('scroll', handleScroll)
      }
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
    return () => null
  }, [hideBackgroundTiles, components?.length])

  useSsrEffect(() => {
    fetch(pageType, pageSlug, locale)
  }, [fetch, pageType, pageSlug, locale])

  useSsrEffect(() => {
    if (hasError) {
      redirect(URLS.HOME_URL)
    }
  }, [redirect, hasError])

  return (
    <>
      <Helmet>
        {pageContent?.fields?.seo?.seoTitle && <title>{pageContent.fields.seo.seoTitle}</title>}
        {pageContent?.fields?.seo?.metaDescription && (
          <meta name="description" content={pageContent?.fields?.seo?.metaDescription} />
        )}
      </Helmet>
      {isLoading && <FullScreenLoader />}
      <div lang={mappedLocale}>
        <WebsiteCmsPageWrapper id="website-cms-page-wrapper">
          {!pageContent?.fields?.hideBackgroundGradient && (
            <GradientLayer id="gradient-layer" className="gradientLayer" />
          )}
          {!pageContent?.fields?.hideBackgroundTiles && (
            <>
              <BackgroundTile1 ref={backgroundTile1Ref} />
              <BackgroundTile2 ref={backgroundTile2Ref} />
            </>
          )}
          {components?.map((component, index) => {
            const Component = component?.type ? componentsMap[component?.type] : null
            const ComponentWrapper = index === 0 ? Wrapper : React.Fragment

            if (Component && component?.type === 'button') {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <ComponentWrapper key={`component?.type-${index}`}>
                  <Component
                    {...component?.fields}
                    isLastComponent={index === components.length - 1}
                    onClick={() => scrollToComponent(component?.fields?.scrollToComponentIndex)}
                  />
                </ComponentWrapper>
              )
            }
            return (
              Component && (
                // eslint-disable-next-line react/no-array-index-key
                <ComponentWrapper key={`component?.type-${index}`}>
                  <VerticalScrollWrapper
                    ref={(el: HTMLSpanElement) => (scrollRefs.current[index] = el)}
                  >
                    <Component
                      {...component?.fields}
                      isLastComponent={index === components.length - 1}
                    />
                  </VerticalScrollWrapper>
                </ComponentWrapper>
              )
            )
          })}
        </WebsiteCmsPageWrapper>
      </div>
    </>
  )
}

CmsPage.propTypes = propTypes
CmsPage.defaultProps = defaultProps

export default React.memo(CmsPage)
